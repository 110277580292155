import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: null,
  userData: null,
  currentChild: null,
};

export const getUserData = createAsyncThunk(
  "user/getUserData",
  async (user) => {
    let data = JSON.stringify({
      query:
        "query UserByUidChildren($uid: String!) { userByUid(uid: $uid) { email id  uid  currentChildId  children { name id  age imgUrl  birthday_date gender childAccess {  id user {    id  email }  child {      id     name      }      }    } childAccesses {  viewed     id    viewed    accepted    createdAt     user_id     child { name  id   age    imgUrl    birthday_date   gender   user {    id   email   displayName    }  }  } notifications {  id  title     body      type     viewed     createdAt    }  } }",
      variables: { uid: user.uid },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
        authorization: user.token ? `Bearer ${user.token} ${user.uid}` : "",
        role: "mobile",
      },
      data: data,
    };

    let response = axios

      .request(config)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userData = action.payload;
      if (action?.payload?.data) {
        if (action?.payload?.data?.userByUid?.children?.length > 0) {
          state.currentChild = action.payload.data.userByUid.children.find(
            (el) =>
              Number(el.id) ===
              Number(action.payload.data.userByUid.currentChildId)
          );
        } else {
          if (action?.payload?.data?.userByUid?.childAccesses?.length > 0) {
            state.currentChild =
              action.payload.data.userByUid.childAccesses.find(
                (el) =>
                  Number(el.child.id) ===
                  Number(action.payload.data.userByUid.currentChildId)
              )?.child;
          } else {
            state.currentChild = null;
          }
        }
      }
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
